@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600;700;800;900&display=swap");

:root {
  --color-primary: #d53f41;
  --color-dark: #626262;
  --color-darker: #464646;
  --color-light: #dbd8d6;
  --color-lighter: #f5f0ec;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 1px;
  background-color: var(--color-lighter);
  color: var(--color-darker);
}

.wrapper {
  margin: 0 5vw;
}

section {
  padding: 2vw 0;
}

.section-title {
  font-family: "Syncopate", sans-serif;
  font-size: 1.5rem;
  padding-bottom: 5vw;
  text-transform: lowercase;
  color: var(--color-primary);
}

.min-h-100vh {
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 2rem 0;
  font-family: "Syncopate", sans-serif;
  font-size: 1.5rem;
  text-transform: lowercase;
}

.links,
.favourite-link {
  font-weight: 700;
}

.links a,
.favourite-link a {
  color: var(--color-dark);
  position: relative;
}

.links a::after,
.favourite-link a::after {
  content: "";
  width: 0%;
  height: 0.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-primary);
  transition: 0.3s;
}
.links a:hover::after,
.favourite-link a:hover::after {
  width: 120%;
}

.logo {
  font-family: "Syncopate", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
}

.logo a {
  color: var(--color-darker);
}

.hero {
  font-family: "Bai Jamjuree";
  text-transform: uppercase;
  text-align: center;
  font-size: 10vw;
  line-height: 1.2;
  min-height: 120vh;
  color: var(--color-darker);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ethereal,
.canvas {
  position: relative;
}

.ethereal span,
.canvas span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-lighter);
}

.photos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.photo {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.photo.one {
  grid-column: 1;
  grid-row: 4;
}
.photo.two {
  grid-column: 4;
  grid-row: 3;
}
.photo.three {
  grid-column: 3;
  grid-row: 5;
}
.photo.four {
  grid-column: 7;
  grid-row: 4;
}
.photo.five {
  grid-column: 5;
  grid-row: 1;
}

.features {
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
  gap: 10rem;
}

.feature-text {
  letter-spacing: 3px;
  font-weight: 500;
  text-transform: uppercase;
}
.features img {
  width: 100%;
}

.feature-left,
.feature-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.feature-shutter-l,
.feature-shutter-r {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: var(--color-lighter);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.about p {
  font-size: 3vw;
  line-height: 1.5;
}

.about p:last-child {
  margin-top: 3vw;
}

.gallery-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 10vw;
  background-color: var(--color-primary);
  padding: 10vw;
}

.gallery-item {
  position: relative;
  width: 50%;
}

.gallery-item-title {
  position: absolute;
  top: 10%;
  left: -50%;
  font-family: "Bai Jamjuree";
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 8vw;
  color: var(--color-lighter);
  z-index: 1;
  mix-blend-mode: color-dodge;
}
.gallery-item-category {
  position: absolute;
  left: 0;
  bottom: -6.5%;
  color: var(--color-lighter);
  text-transform: uppercase;
  letter-spacing: 10px;
  z-index: 1;
}
.gallery-item-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.footer {
  text-align: center;
}

.footer h1 {
  font-family: "Bodoni Moda", serif;
  font-size: 10vw;
  text-transform: lowercase;
  color: var(--color-primary);
}

.not-found {
  margin: 0.2rem 8rem;
  font-family: "Bai Jamjuree";
  font-size: 11rem;
  line-height: 1;
  font-weight: 700;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 3rem;
  position: relative;
}

.nf-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.nf-img-container {
  max-height: 200px;
  max-width: 200px;
}

.nf-image1,
.nf-image2 {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.nf-image2 {
  margin-left: 25rem;
}

.blog {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: var(--color-lighter);
  gap: 0.5rem;
}

.blog-grid1 {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  background-image: url("https://img.freepik.com/free-photo/impressive-bright-color-brush-falling-powder_23-2148209037.jpg?w=740&t=st=1671358880~exp=1671359480~hmac=216ef77ae16e7e48422f12b53c46a89b1d00f4d5673886a1ae851078f18f5667");
  position: relative;
  border-radius: 1.5rem 0 0 0;
}
.blog-grid2 {
  grid-row: 3 / span 2;
  grid-column: 1 / span 2;
  background-image: url("https://img.freepik.com/free-photo/amazing-flying-colorful-dye-cloud_23-2148209031.jpg?w=740&t=st=1671359018~exp=1671359618~hmac=c16a8555d41bad514a7a270a1dee25a355acdc8c74c31922f4297577b38eaab6");
  position: relative;
  border-radius: 0 0 0 1.5rem;
}
.blog-grid3 {
  grid-row: 1 / span 3;
  background-image: url("https://img.freepik.com/free-photo/road-rock-formation-table-mountain-national-park-africa_181624-14785.jpg?w=740&t=st=1671359197~exp=1671359797~hmac=a1fd980b30180e4e37d11936b5e1163ca45f2619f9efb2a8d3ccb1f89a9017e8");
  position: relative;
}
.blog-grid4 {
  grid-row: 4 / span 1;
  grid-column: 3 / span 3;
  background-image: url("https://img.freepik.com/free-photo/beautiful-view-greenery-bridge-forest-perfect-background_181624-17827.jpg?w=740&t=st=1671359381~exp=1671359981~hmac=2124efddcaf0a744ada556b71bdac53f714895142d562cbeb5df825804fb099b");
  position: relative;
  border-radius: 0 0 1.5rem 0;
}

.blog-grid5 {
  grid-row: 1 / span 1;
  grid-column: 4 / span 2;
  background-image: url("https://img.freepik.com/free-photo/closeup-shot-ferns-spots-fern-are-spores_181624-36493.jpg?w=740&t=st=1671359227~exp=1671359827~hmac=20dc100b9b69ae4f5b743c9bfb84724eb495f8a710326b344bff5eceed97547c");
  position: relative;
  border-radius: 0 1.5rem 0 0;
}
.blog-grid6 {
  grid-row: 2 / span 1;
  grid-column: 4 / span 2;
  background-image: url("https://img.freepik.com/free-photo/beautiful-shot-steep-hills-desert-with-amazing-cloudy-sky_181624-889.jpg?w=740&t=st=1671359317~exp=1671359917~hmac=14b206570bcde5e2bc1a4d0e5f8796180bfeea4ded34bac657069cfe81a103f1");
  position: relative;
}
.blog-grid7 {
  grid-row: 3 / span 1;
  grid-column: 4 / span 2;
  background-image: url("https://img.freepik.com/free-photo/beautiful-shot-desert-with-trails-sand-rocky-hills-calm-sky_181624-4547.jpg?w=740&t=st=1671359343~exp=1671359943~hmac=488644b9458af6de9e179c75c5b65f4fe41f2a3edf8a01332f155689df1fdfa4");
  position: relative;
}

.blog-details {
  position: absolute;
  bottom: 0;
  padding: 0.3rem;
  z-index: 3;
  transition: all 0.3s;
}

.blog-grid1:hover .blog-details {
  text-align: center;
  bottom: 40%;
}

.blog-after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  transition: all 0.3s;
}
.blog-grid1:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 1.5rem 0 0 0;
}

.blog-grid2:hover .blog-details {
  text-align: center;
  bottom: 40%;
}
.blog-grid2:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 0 0 0 1.5rem;
}
.blog-grid3:hover .blog-details {
  text-align: center;
  bottom: 40%;
}
.blog-grid3:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.blog-grid6:hover .blog-details,
.blog-grid7:hover .blog-details {
  text-align: center;
  bottom: 20%;
}

.blog-grid6:hover .blog-after,
.blog-grid7:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.blog-grid4:hover .blog-details {
  text-align: center;
  bottom: 20%;
  left: 16%;
}
.blog-grid4:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 0 0 1.5rem 0;
}

.blog-grid5:hover .blog-details {
  text-align: center;
  bottom: 20%;
}
.blog-grid5:hover .blog-after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
  border-radius: 0 1.5rem 0 0;
}
